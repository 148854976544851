<template>
  <div class="container">
    <bread-crumb :navigationArray="navigationLink"/>
    <div v-if="getDetailedAboutTheWard && getDetailedAboutTheWard.text" v-html="getDetailedAboutTheWard.text"></div>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
  name: 'AboutTheWard',
  components: {
    BreadCrumb
  },
  created () {
    this.$store.dispatch('setAboutTheWard')
    // this.$store.dispatch('setDetailedAboutTheWard', this.$route.params.id)
  },
  computed: {
    getDetailedAboutTheWard () {
      const data = this.$store.getters.getAboutTheWard.filter(item => {
        if (this.$store.getters.GET_LANG && item.lang === 'ru') {
          return item
        } else if (!this.$store.getters.GET_LANG && item.lang === 'eng') {
          return item
        }
      })
      return data[0]
    },
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'О палате' : 'About the Chamber'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .container {
    margin-bottom: 3rem;
  }
</style>
